/* eslint-disable jsx-a11y/alt-text */
import type { DeviceoutputMapped } from '@/core/features/cookies/cookies-service';
import type { ImageProps } from '@/core/features/image-v2/image';
import type { ZoomPanPinchProps } from '@/core/features/zoom-pan-pinch/zoom-pan-pinch-wrapper';

import React from 'react';

import dynamic from 'next/dynamic';

import { Image } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';

export type ResponseImageLayoutWidth = 10 | 20 | 25 | 33 | 50 | 66 | 88 | 100;

const ZoomPanPinchWrapper = dynamic(
    () => import(/* webpackChunkName: "ZoomPanPinchWrapper" */ '@/core/features/zoom-pan-pinch/zoom-pan-pinch-wrapper'),
);

const DEFAULT_WIDTH_MOBILE = 400;
const DEFAULT_WIDTH_DESKTOP = 1280;

export type ResponsiveImageProps = Omit<ImageProps, 'height' | 'width'> & {
    className?: string;
    height: 'full' | number;
    layoutWidth: ResponseImageLayoutWidth;
    zoom?: ZoomPanPinchProps | true;
};

const ResponsiveImage = (props: ResponsiveImageProps) => {
    const { deviceoutputMapped } = useDeviceoutput();
    const width = getWidth(props.layoutWidth, deviceoutputMapped);
    const mappedHeight = getHeight(props.height);
    const { className, zoom, ...imageProps } = props;

    return (
        <div
            className={`overflow-hidden relative width-100 ${className ?? ''}`}
            style={{
                height: props.height === 'full' ? undefined : `${props.height}px`,
            }}
        >
            {zoom ? (
                <ZoomPanPinchWrapper {...(typeof zoom !== 'boolean' && zoom)}>
                    <Image
                        {...imageProps}
                        height={mappedHeight}
                        width={width}
                    />
                </ZoomPanPinchWrapper>
            ) : (
                <Image
                    {...imageProps}
                    height={mappedHeight}
                    width={width}
                />
            )}
        </div>
    );
};

export default React.memo(ResponsiveImage);

const getWidth = (layoutWidth: ResponseImageLayoutWidth, deviceoutputMapped: DeviceoutputMapped): number => {
    if (deviceoutputMapped === 'desktop') {
        return Math.ceil(DEFAULT_WIDTH_DESKTOP * (layoutWidth / 100));
    }
    return Math.ceil(DEFAULT_WIDTH_MOBILE * (layoutWidth / 100));
};

const getHeight = (height: 'full' | number): number => {
    if (height === 'full') {
        return 780;
    }

    return height;
};
