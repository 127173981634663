/* eslint-disable @next/next/no-img-element */
import React from 'react';

import NextHead from 'next/head';

import { getCdnImageUrl } from '@/core/features/image-v2/image-utils';
import { classnames } from '@/core/utils/bem-classname';

import styles from './icon-image.module.scss';

type IconImageProps = {
    alt?: string;
    className?: string;
    disablePreload?: boolean;
    height: number;
    inline?: boolean;
    maxHeight?: number;
    maxWidth?: number;
    url: string;
    width: number;
};

const _IconImage = (props: IconImageProps) => {
    const { alt = '', className, disablePreload, height, inline = false, maxHeight, maxWidth, url, width } = props;
    const src = getCdnImageUrl(url, width * 2, height * 2);

    return (
        <div
            className={classnames(className, { 'inline-block': inline })}
            style={{
                height: maxHeight ? undefined : `${height}px`,
                maxHeight: maxHeight ? `${maxHeight}px` : undefined,
                maxWidth: maxWidth ? `${maxWidth}px` : undefined,
                width: maxWidth ? undefined : `${width}px`,
            }}
        >
            <img
                alt={alt}
                className={styles.iconImage}
                height={height}
                loading={'eager'}
                src={src}
                width={width}
            />
            {!disablePreload && (
                <NextHead>
                    <link
                        as={'image'}
                        href={src}
                        rel={'preload'}
                    />
                </NextHead>
            )}
        </div>
    );
};

export default React.memo(_IconImage);
